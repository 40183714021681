import {
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useBreakpointValue,
  Square,
  Text,
  Skeleton,
  SkeletonCircle,
} from "@chakra-ui/react";
import Sidebar from "@components/sidebar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Search from "@components/search";
import { useFirebase } from "@context/firebase.context";
import { signOut } from "firebase/auth";
import { useEffect } from "react";
import _ from "lodash-es";
import HStack from "@components/stack/hstack";
import { useTranslation } from "react-i18next";

export default function Navbar({
  isSidebarOpen,
  onSidebarOpen,
  onSidebarClose,
}) {
  const { t } = useTranslation();
  const { auth, user, userInfo, isAuthLoading } = useFirebase();
  const sidebarEnabled = useBreakpointValue({
    base: true,
    lg: false,
  });
  const navigate = useNavigate();
  const location = useLocation();
  const logout = async () => {
    await signOut(auth);
  };

  useEffect(() => {
    onSidebarClose();
  }, [location.pathname, onSidebarClose]);

  return (
    <>
      <HStack
        backgroundColor={"gray.900"}
        zIndex={50}
        position={"fixed"}
        width={"full"}
        height={16}
        paddingRight={{
          base: 4,
          md: 8,
        }}
        paddingLeft={{
          base: 4,
          md: 6,
        }}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <HStack>
          <Button
            variant={"ghost"}
            onClick={() => sidebarEnabled && onSidebarOpen()}
            aria-label="Toggle Navbar"
          >
            <i className="fa-regular fa-bars"></i>
          </Button>
          <Link to="/">
            <Center
              onClick={() => {
                navigate("/");
              }}
              fontSize={"xl"}
              className="logo"
              cursor={"pointer"}
              marginLeft={2}
              userSelect={false}
            >
              <Text>Mizugi Space</Text>
              {/* <Image src="/images/logo/full.png" height={6} /> */}
            </Center>
          </Link>
        </HStack>
        <HStack>
          <Search />
          {!isAuthLoading && user && (
            <>
              {process.env.REACT_APP_ENABLE_DEPOSIT === "true" && (
                <Button
                  onClick={() => navigate("/deposit")}
                  variant={"ghost"}
                  aria-label="Deposit"
                >
                  <Text>
                    <i className="fa-regular fa-wallet"></i>
                  </Text>
                  <Text ml={2}>{_.get(userInfo, "wallet.balance", 0)}</Text>
                </Button>
              )}

              <Menu>
                <MenuButton
                  display={{
                    base: "none",
                    sm: "flex",
                  }}
                  cursor={"pointer"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  width={10}
                  height={10}
                  fontSize={"2xl"}
                  aria-label="User"
                >
                  <Text>
                    <i className="fa-light fa-circle-user" />
                  </Text>
                </MenuButton>
                <MenuList width={72}>
                  {_.get(userInfo, "roles.admin", false) && (
                    <Link to={"/admin"} width={"full"}>
                      <MenuItem>
                        <HStack width={"full"} cursor={"pointer"}>
                          <Square width={8} height={8}>
                            <Center>
                              <i className="fa-regular fa-ruler"></i>
                            </Center>
                          </Square>
                          <Text>{t("components.navbar.admin")}</Text>
                        </HStack>
                      </MenuItem>
                    </Link>
                  )}
                  <Link to={"/settings"} width={"full"}>
                    <MenuItem>
                      <HStack width={"full"} cursor={"pointer"}>
                        <Square width={8} height={8}>
                          <Center>
                            <i className="fa-regular fa-gear"></i>
                          </Center>
                        </Square>
                        <Text>{t("components.navbar.settings")}</Text>
                      </HStack>
                    </MenuItem>
                  </Link>
                  <MenuItem>
                    <HStack onClick={logout} width={"full"} cursor={"pointer"}>
                      <Square width={8} height={8}>
                        <Center>
                          <i className="fa-regular fa-arrow-right-from-bracket"></i>
                        </Center>
                      </Square>
                      <Text>{t("components.navbar.logout")}</Text>
                    </HStack>
                  </MenuItem>
                </MenuList>
              </Menu>
            </>
          )}
          {!isAuthLoading && !user && (
            <Button onClick={() => navigate("/auth/login")} fontSize={"sm"}>
              <Text>
                <i className="fa-regular fa-right-to-bracket"></i>
              </Text>
              <Text ml={2}>{t("components.navbar.login")}</Text>
            </Button>
          )}
          {isAuthLoading && (
            <HStack>
              <Skeleton height={6} marginRight={4} width={10} />
              <Center width={10}>
                <SkeletonCircle width={8}></SkeletonCircle>
              </Center>
            </HStack>
          )}
        </HStack>
      </HStack>
      <Drawer
        isOpen={isSidebarOpen}
        onClose={onSidebarClose}
        placement={"left"}
      >
        <DrawerOverlay />
        <DrawerContent maxWidth={64}>
          <DrawerHeader height={16}>
            <Link to="/">
              <HStack>
                <Button variant={"ghost"} onClick={onSidebarClose}>
                  <i className="fa-regular fa-bars"></i>
                </Button>
                <Center
                  fontSize={"xl"}
                  className="logo"
                  userSelect={"none"}
                  marginLeft={2}
                >
                  <Text>Mizugi Space</Text>
                  {/* <Image src="/images/logo/full.png" height={6} /> */}
                </Center>
              </HStack>
            </Link>
          </DrawerHeader>

          <DrawerBody id="sidebar-drawer" paddingX={0}>
            <Sidebar />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

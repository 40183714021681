import { useMatch } from "react-router-dom";
import VStack from "@components/stack/vstack";
import SidebarItem from "@components/sidebar/item";

export default function AdminSidebar() {
  return (
    <>
      <VStack
        width={"full"}
        paddingX={4}
        paddingY={4}
        borderBottomWidth={"1px"}
        borderColor={"gray.700"}
      >
        <SidebarItem
          to="/admin"
          title={"首頁"}
          icon={<i className="fa-regular fa-house"></i>}
          isActive={useMatch({ path: "/admin" })}
        />
      </VStack>
      <VStack
        width={"full"}
        paddingX={4}
        paddingY={4}
        borderBottomWidth={"1px"}
        borderColor={"gray.700"}
      >
        <SidebarItem
          to="/admin/users"
          title={"使用者管理"}
          icon={<i className="fa-regular fa-users"></i>}
          isActive={useMatch({ path: "/admin/users" })}
        />
        <SidebarItem
          to="/admin/works"
          title={"作品管理"}
          icon={<i className="fa-regular fa-image"></i>}
          isActive={useMatch({ path: "/admin/works" })}
        />
        <SidebarItem
          to="/admin/casts"
          title={"出演者管理"}
          icon={<i className="fa-regular fa-people-simple"></i>}
          isActive={useMatch({ path: "/admin/casts" })}
        />
        <SidebarItem
          to="/admin/deposits"
          title={"儲值管理"}
          icon={<i className="fa-regular fa-money-simple-from-bracket"></i>}
          isActive={useMatch({ path: "/admin/deposits" })}
        />
      </VStack>
      <VStack
        width={"full"}
        paddingX={4}
        paddingY={4}
        borderBottomWidth={"1px"}
        borderColor={"gray.700"}
      >
        <SidebarItem
          to="/"
          title={"前台首頁"}
          icon={<i className="fa-regular fa-arrow-up-right-from-square"></i>}
          isActive={false}
        />
      </VStack>
    </>
  );
}

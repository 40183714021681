import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import { ChakraProvider, ColorModeScript, extendTheme } from "@chakra-ui/react";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { FirebaseProvider } from "@context/firebase.context";
import { AppProvider } from "@context/app.context";
import * as Sentry from "@sentry/react";
import enLocale from "@resources/locales/en.json";
import zhTWLocale from "@resources/locales/zh-TW.json";
import jaLocale from "@resources/locales/ja.json";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import CallbackRoutes from "@routes/callback.routes";
import GeneralRoutes from "@routes/general.routes";
import AuthRoutes from "@routes/auth.routes";
import AdminRoutes from "@routes/admin.routes";

console.log(
  "%cWhat are you looking for? I am watching you.",
  "font-size: 24px; color: red;",
);
console.log("%cლ(́◕◞౪◟◕‵ლ)", "font-size: 24px; color: red;");

if (process.env.REACT_APP_SENTRY_DSN) {
  console.log(process.env.REACT_APP_SENTRY_DSN);
  Sentry.init({
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT || "develop",
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate:
      process.env.REACT_APP_SENTRY_ENVIRONMENT === "production" ? 0.1 : 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate:
      process.env.REACT_APP_SENTRY_ENVIRONMENT === "production" ? 0.1 : 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

// serviceWorkerRegistration.register();

i18n
  // .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: "zh-TW", // use et if detected lng is not available
    saveMissing: true, // send not translated keys to endpoint
    debug: true,
    whitelist: ["zh-TW", "en", "ja"],
    resources: {
      en: {
        translation: enLocale,
      },
      ja: {
        translation: jaLocale,
      },
      "zh-TW": {
        translation: zhTWLocale,
      },
    },
    detection: {
      order: ["querystring", "localStorage"],
      lookupQuerystring: "locale",
      lookupLocalStorage: "locale",
      caches: ["localStorage", "cookie"],
    },
    react: {
      useSuspense: false,
    },
  });

localStorage.setItem("chakra-ui-color-mode", "dark");

const theme = extendTheme({
  initialColorMode: "dark",
  useSystemColorMode: false,
  colors: {
    // gray: {
    //   100: "#bcbabb", // 在淺灰中加入一點橘紅色
    //   200: "#b0a8a9", // 輕微橘紅色調的中淺灰
    //   300: "#989697", // 標準灰帶一點橘紅色
    //   400: "#828081", // 中深灰帶橘紅色調
    //   500: "#666465", // 深灰帶微妙的橘紅色調
    //   600: "#504849", // 更深灰色帶橘紅色基底
    //   700: "#343233", // 接近黑色的深灰帶橘紅色調
    //   800: "#181617", // 更接近黑色的灰帶橘紅色調
    //   900: "#100809", // 極深灰色（幾乎是黑色）帶微妙的橘紅色基底
    // },
  },
  sizes: {
    100: "25rem",
    102: "25.5rem",
    104: "26rem",
    106: "26.5rem",
    108: "27rem",
    110: "27.5rem",
    112: "28rem",
    114: "28.5rem",
    116: "29rem",
    118: "29.5rem",
    120: "30rem",
    160: "40rem",
    180: "45rem",
    200: "50rem",
    240: "60rem",
    280: "70rem",
    320: "80rem",
  },
  components: {
    Link: {
      baseStyle: {
        _hover: {
          textDecoration: "none", // 這裡設置 hover 時不顯示文字裝飾
        },
      },
    },
  },
  shadows: {
    outline: "none",
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <ColorModeScript initialColorMode={"dark"} />

    <BrowserRouter>
      <ChakraProvider theme={theme}>
        {/* <ReactReduxProvider store={store}> */}
        <FirebaseProvider>
          <AppProvider>
            <Routes>
              <Route path="/callback/*" element={<CallbackRoutes />}></Route>
              <Route path="/auth/*" element={<AuthRoutes />}></Route>
              <Route path="/admin/*" element={<AdminRoutes />}></Route>
              <Route path="/*" element={<GeneralRoutes />}></Route>
            </Routes>
          </AppProvider>
        </FirebaseProvider>
        {/* </ReactReduxProvider> */}
      </ChakraProvider>
    </BrowserRouter>
  </>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
serviceWorkerRegistration.register();

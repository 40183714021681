import { createContext, useContext, useEffect, useState } from "react";
import _ from "lodash-es";
import { useMemo } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { useCallback } from "react";
import dayjs from "dayjs";
import "dayjs/locale/ja";
import "dayjs/locale/en";
import "dayjs/locale/zh-tw";
const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [activePage, setActivePage] = useState("");
  const { pathname } = useLocation();
  const [r18, setR18] = useState(null);
  const { i18n } = useTranslation();
  const [isTappayLoaded, setIsTappayLoaded] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const getInfo = (info, field, locale = "zh-TW", defaultValue = "") => {
    let targetLocale = _.get(info, locale, null);
    targetLocale = targetLocale ? targetLocale : _.get(info, locale, "zh-TW");
    return _.get(targetLocale, field, defaultValue);
  };

  const formatMoment = useCallback(
    (datetime, format = "MMMM DD YYYY, h:mm:ss a") => {
      if (!datetime) return "";
      switch (i18n.language) {
        case "en":
        default:
          return dayjs(datetime).locale("en").format(format);
        case "ja":
          return dayjs(datetime).locale("ja").format(format);
        case "zh-TW":
          return dayjs(datetime).locale("zh-tw").format(format);
      }
    },
    [i18n.language],
  );

  const TPDirect = useMemo(() => {
    if (isTappayLoaded) {
      global.TPDirect.setupSDK(
        "125558",
        "app_CgNJE5Y1dkVCXqbTRLHLN9JmuTWxtdF37nN4KFC1TNZll9G8crgtbntfLGcM",
        process.env.REACT_APP_TAPPAY_SERVER_TYPE || 'sandbox',
      );
      return global.TPDirect;
    }
    return null;
  }, [isTappayLoaded]);

  const toastPosition = useBreakpointValue({
    base: "top",
    md: "bottom-left",
  });

  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://js.tappaysdk.com/sdk/tpdirect/v5.17.0";
    script.onload = () => {
      setIsTappayLoaded(true);
    };
    document.head.appendChild(script);
  }, []);

  useEffect(() => {
    const r18 = localStorage.getItem("r18");
    setR18(r18 === "true");
  }, []);

  const consentR18 = () => {
    localStorage.setItem("r18", "true");
    setR18(true);
  };

  const value = {
    getInfo,
    formatMoment,
    setActivePage,
    activePage,
    isTappayLoaded,
    setIsTappayLoaded,
    TPDirect,
    toastPosition,
  };

  return (
    <>
      <AppContext.Provider value={value}>
        {children}
        {r18 !== null && !r18 && (
          <Modal
            isOpen={true}
            size={{
              base: "xs",
              sm: "sm",
            }}
          >
            <ModalOverlay
              backgroundColor={"rgba(0,0,0,0.5)"}
              backdropFilter={"blur(10px)"}
            />
            <ModalContent>
              <ModalHeader textAlign={"center"}>
                <Trans i18nKey={"components.r18.title"} />
              </ModalHeader>
              <ModalBody textAlign={"center"}>
                <Text fontSize={"6xl"} color={"orange.500"}>
                  <i className="fa-regular fa-circle-exclamation"></i>
                </Text>
                <Text marginTop={4} fontSize={"sm"} textAlign={"center"}>
                  <Trans i18nKey={"components.r18.description"} />
                </Text>
              </ModalBody>
              <ModalFooter>
                <Button
                  onClick={() => window.location.replace("https://google.com")}
                >
                  <Trans i18nKey={"components.r18.no"} />
                </Button>
                <Button
                  marginLeft={2}
                  colorScheme="orange"
                  onClick={consentR18}
                >
                  <Trans i18nKey={"components.r18.yes"} />
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        )}
      </AppContext.Provider>
    </>
  );
};

export function useApp() {
  return useContext(AppContext);
}

import { useEffect } from "react";
import { useFirebase } from "@context/firebase.context";
import _ from "lodash";
export default function MustAdminLayout({ children }) {
  const { isAuthLoading, user, userInfo } = useFirebase();
  useEffect(() => {
    console.log(!isAuthLoading);
    console.log(!user || !_.get(userInfo, "roles.admin", false));
    if (!isAuthLoading && (!user || !_.get(userInfo, "roles.admin", false))) {
      console.log(123);
      window.location.replace("/");
    }
  }, [isAuthLoading, user, userInfo]);

  return <>{children}</>;
}

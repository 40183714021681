// import { useEffect } from "react";
// import { Helmet } from "react-helmet";
// import { useFirebase } from "@context/firebase.context";
// import { collection, getDocs, limit, orderBy, query } from "firebase/firestore";
// import { useMemo } from "react";
// import VideoList from "@components/video-list";
import MainContainer from "@components/container/main";
import BodyContainer from "@components/container/body";
import { useCallback, useState } from "react";
import axios from "axios";
// import { useExpandWork } from "@hooks/expand-work";
import {
  // Box,
  // HStack,
  Text,
  Image,
  Flex,
  SimpleGrid,
  UnorderedList,
  ListItem,
  Input,
  FormControl,
  FormLabel,
  Button,
  FormErrorMessage,
  useToast,
  Circle,
} from "@chakra-ui/react";
import { Trans, useTranslation } from "react-i18next";
import { object, string } from "yup";
import { useApp } from "context/app.context";
import { Link } from "react-router-dom";

const galleries = [
  { slug: "vol-22001", title: "vol.22001", released: false },
  { slug: "vol-22002", title: "vol.22002", released: false },
  { slug: "vol-22003", title: "vol.22003", released: false },
  { slug: "vol-23001", title: "vol.23001", released: false },
  { slug: "vol-23002", title: "vol.23002", released: false },
  { slug: "vol-23003", title: "vol.23003", released: true },
  { slug: "vol-23004", title: "vol.23004", released: true },
  { slug: "vol-23005", title: "vol.23005", released: true },
  { slug: "vol-23006", title: "vol.23006", released: true },
  { slug: "vol-23007", title: "vol.23007", released: true },
  { slug: "vol-23008", title: "vol.23008", released: true },
  { slug: "vol-23009", title: "vol.23009", released: true },
  { slug: "vol-23010", title: "vol.23010", released: true },
  { slug: "vol-23011", title: "vol.23011", released: true },
  { slug: "vol-23012", title: "vol.23012", released: true },
  { slug: "vol-23013", title: "vol.23013", released: true },
  { slug: "vol-23014", title: "vol.23014", released: true },
  { slug: "vol-23015", title: "vol.23015", released: true },
  { slug: "vol-23016", title: "vol.23016", released: true },
  { slug: "vol-23017", title: "vol.23017", released: true },
  { slug: "vol-23018", title: "vol.23018", released: true },
  { slug: "vol-23019", title: "vol.23019", released: true },
  { slug: "amoresy-2023", title: "Amoresy 2023", released: true },
  { slug: "armed-experiment", title: "Swimsuit x Katana", released: true },
].reverse();
function Index() {
  const { i18n, t } = useTranslation();
  const { toastPosition } = useApp();
  // const { firestore } = useFirebase();
  // const expandWork = useExpandWork();
  // const q = useMemo(() => {
  //   return query(
  //     collection(firestore, "works"),
  //     orderBy("createdAt", "desc"),
  //     limit(24),
  //   );
  // }, [firestore]);

  // const loadWorks = useCallback(async () => {
  //   const works = await new Promise((resolve) => {
  //     getDocs(q).then((querySnapshot) => {
  //       let results = [];
  //       querySnapshot.forEach((doc) => {
  //         results.push(doc.data());
  //       });
  //       resolve(results);
  //     });
  //   });
  //   const expandWorks = await Promise.all(
  //     works.map((work) => {
  //       return expandWork(work);
  //     }),
  //   );
  //   return expandWorks;
  // }, [q, expandWork]);
  const toast = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [email, setEmail] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [name, setName] = useState("");
  const [nameErrorMessage, setNameErrorMessage] = useState("");
  const submit = useCallback(async () => {
    setNameErrorMessage("");
    setEmailErrorMessage("");
    setIsSubmitting(true);
    const schema = object({
      name: string().required(),
      email: string().email().required(),
    });
    try {
      schema.validateSync(
        {
          name,
          email,
        },
        { abortEarly: false },
      );
    } catch (errors) {
      for (const error of errors.inner) {
        switch (`${error.path}-${error.type}`) {
          case "name-required":
            setNameErrorMessage(
              t("announcement.release.form.error_message.name_empty"),
            );
            break;
          case "email-required":
            setEmailErrorMessage(
              t("announcement.release.form.error_message.email_empty"),
            );
            break;
          case "email-email":
            setEmailErrorMessage(
              t("announcement.release.form.error_message.email_format_invalid"),
            );
            break;
          default:
            break;
        }
      }
      setIsSubmitting(false);
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_USER_API_BASE_URL}/listeners`,
        {
          name,
          email,
          locale: i18n.language,
        },
      );
      console.log(response);
    } catch (error) {}
    toast({
      title: t("announcement.release.form.submitted"),
      duration: 2000,
      status: "success",
      position: toastPosition,
    });
    setIsSubmitting(false);
  }, [setIsSubmitting, email, name, t, toast, toastPosition, i18n]);

  return (
    <>
      <MainContainer>
        <BodyContainer marginBottom={12}>
          <Flex
            position={"relative"}
            height={96}
            backgroundColor={"orange.100"}
            marginTop={4}
            width={"full"}
            borderRadius={"md"}
            justifyContent={"center"}
            alignItems={"center"}
            py={4}
          >
            <Text
              color="black"
              position={"absolute"}
              fontSize={"2xl"}
              fontWeight={"bold"}
              top={4}
              left={4}
            >
              <Trans i18nKey={"announcement.release.text.whats_new"} />
            </Text>
            <Image
              position={"absolute"}
              width={64}
              right={4}
              bottom={4}
              src="/images/announcement.svg"
            />
          </Flex>
          <SimpleGrid marginTop={4} spacing={4}>
            <Text fontSize={"2xl"} fontWeight={"bold"}>
              <Trans i18nKey={"announcement.release.text.website_update"} />
            </Text>
            <UnorderedList spacing={2}>
              <ListItem>
                <Trans
                  i18nKey={"announcement.release.text.website_update_1"}
                  components={[null, <Text as={"b"} color={"orange.500"} />]}
                />
              </ListItem>
              <ListItem>
                <Trans
                  i18nKey={"announcement.release.text.website_update_2"}
                  components={[null, <Text as={"b"} color={"orange.500"} />]}
                />
              </ListItem>
            </UnorderedList>
            <Text fontSize={"2xl"} fontWeight={"bold"}>
              <Trans i18nKey={"announcement.release.text.get_free_points"} />
            </Text>
            <UnorderedList spacing={2}>
              <ListItem>
                <Trans
                  i18nKey={"announcement.release.text.get_free_points_1"}
                />
              </ListItem>
              <ListItem>
                <Trans
                  i18nKey={"announcement.release.text.get_free_points_2"}
                  components={[null, <Text as={"b"} color={"orange.500"} />]}
                />
              </ListItem>
            </UnorderedList>
            <Flex>
              <SimpleGrid width={"100%"} maxWidth={160} spacing={4}>
                <FormControl isInvalid={nameErrorMessage.length > 0}>
                  <FormLabel>
                    <Trans i18nKey={"announcement.release.form.name"} />
                  </FormLabel>
                  <Input
                    placeholder={t(
                      "announcement.release.form.name_placeholder",
                    )}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  {nameErrorMessage.length > 0 && (
                    <FormErrorMessage>{nameErrorMessage}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl isInvalid={emailErrorMessage.length > 0}>
                  <FormLabel>
                    <Trans i18nKey={"announcement.release.form.email"} />
                  </FormLabel>
                  <Input
                    placeholder="email@mizugi.lover"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                  />
                  {emailErrorMessage.length > 0 && (
                    <FormErrorMessage>{emailErrorMessage}</FormErrorMessage>
                  )}
                </FormControl>
                <Button
                  isLoading={isSubmitting}
                  loadingText={t("announcement.release.form.submitting")}
                  onClick={submit}
                  colorScheme={"orange"}
                >
                  <Trans i18nKey={"announcement.release.form.submit"} />
                </Button>
              </SimpleGrid>
            </Flex>
          </SimpleGrid>
          <Flex
            position={"relative"}
            height={96}
            backgroundColor={"gray.100"}
            marginTop={8}
            width={"full"}
            borderRadius={"md"}
            justifyContent={"center"}
            alignItems={"center"}
            py={4}
          >
            <Text
              color="black"
              position={"absolute"}
              fontSize={"2xl"}
              fontWeight={"bold"}
              top={4}
              left={4}
            >
              <Trans i18nKey={"announcement.release.text.about_mizugi_space"} />
            </Text>
            <Image
              position={"absolute"}
              width={64}
              right={4}
              bottom={4}
              src="/images/galleries.svg"
            />
          </Flex>
          <SimpleGrid marginTop={4} spacing={4}>
            <Text fontSize={"2xl"} fontWeight={"bold"}>
              <Trans
                i18nKey={"announcement.release.text.motivation"}
                components={[null, <Text as={"b"} color={"orange.500"} />]}
              />
            </Text>
            <UnorderedList spacing={2}>
              <ListItem>
                <Trans
                  i18nKey={"announcement.release.text.motivation_1"}
                  components={[null, <Text as={"b"} color={"orange.500"} />]}
                />
              </ListItem>
              <ListItem>
                <Trans
                  i18nKey={"announcement.release.text.motivation_2"}
                  components={[null, <Text as={"b"} color={"orange.500"} />]}
                />
              </ListItem>
            </UnorderedList>
            <Text fontSize={"2xl"} fontWeight={"bold"}>
              <Trans
                i18nKey={"announcement.release.text.rewind"}
                components={[null, <Text as={"b"} color={"orange.500"} />]}
              />
            </Text>
            <Text>
              <Trans i18nKey={"announcement.release.text.rewind_1"} />
            </Text>
            <SimpleGrid
              columns={{
                base: 3,
                md: 4,
                lg: 6,
              }}
              marginTop={8}
              spacing={8}
            >
              {galleries.map((gallery) => {
                return gallery.released ? (
                  <Link to={`/galleries/${gallery.slug}`} key={gallery.slug}>
                    <Flex
                      cursor={"pointer"}
                      direction={"column"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Circle
                        size={24}
                        borderWidth={2}
                        borderColor={"orange.500"}
                        backgroundImage={`url('/images/thumbnails/${gallery.slug}.jpg')`}
                        backgroundSize={"cover"}
                        backgroundPosition={"center"}
                        backgroundColor={"gray.100"}
                      ></Circle>
                      <Text textAlign={"center"} marginTop={2} fontSize={"sm"}>
                        {gallery.title}
                      </Text>
                    </Flex>
                  </Link>
                ) : (
                  <Flex
                    onClick={() => {
                      toast({
                        title: t("announcement.release.text.preparing"),
                        duration: 2000,
                        status: "success",
                        position: toastPosition,
                      });
                    }}
                    cursor={"pointer"}
                    direction={"column"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Circle
                      size={24}
                      borderWidth={2}
                      borderColor={"orange.500"}
                      backgroundImage={`url('/images/thumbnails/${gallery.slug}.jpg')`}
                      backgroundSize={"cover"}
                      backgroundPosition={"center"}
                      backgroundColor={"gray.100"}
                    ></Circle>
                    <Text marginTop={2}>{gallery.title}</Text>
                  </Flex>
                );
              })}
            </SimpleGrid>
          </SimpleGrid>
          {/* <VideoList loadWorks={loadWorks} /> */}
        </BodyContainer>
      </MainContainer>
    </>
  );
}

export default Index;

import MainLayout from "@layouts/main.layout";
import Index from "@pages/client/index.page";
import { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";

const FaqIndexPage = lazy(() => import("@pages/client/static/faq/index.page"));
const FaqContentPage = lazy(() =>
  import("@pages/client/static/faq/content.page"),
);
const PrivacyPoliciesPage = lazy(() =>
  import("@pages/client/static/privacy-policies.page"),
);
const TermsOfUsePage = lazy(() =>
  import("@pages/client/static/terms-of-use.page"),
);

// - User Section
const LikedWorksPage = lazy(() =>
  import("@pages/client/user/liked-works.page"),
);
const PurchasedWorkPage = lazy(() =>
  import("@pages/client/user/purchased-works.page"),
);
const PersonalPage = lazy(() => import("@pages/client/user/personal.page"));
const SettingsPage = lazy(() => import("@pages/client/user/settings.page"));

const GalleryPage = lazy(() => import("@pages/client/showcase/gallery.page"));
const VideoPage = lazy(() => import("@pages/client/showcase/video.page"));
const DepositIndexPage = lazy(() => import("@pages/client/deposit/index.page"));
const DepositPaymentPage = lazy(() =>
  import("@pages/client/deposit/payment.page"),
);
const InquiryPage = lazy(() => import("@pages/client/inquiry.page"));
const NotFoundPage = lazy(() => import("@pages/client/errors/404.page"));

export default function GeneralRoutes() {
  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route id="index" index element={<Index />}></Route>
        <Route
          path="faq"
          element={
            <Suspense>
              <FaqIndexPage />
            </Suspense>
          }
        ></Route>
        <Route
          path="faq/:topic"
          element={
            <Suspense>
              <FaqContentPage />
            </Suspense>
          }
        ></Route>
        <Route
          path="terms-of-use"
          element={
            <Suspense>
              <TermsOfUsePage />
            </Suspense>
          }
        ></Route>
        <Route
          path="privacy-policies"
          element={
            <Suspense>
              <PrivacyPoliciesPage />
            </Suspense>
          }
        ></Route>
        <Route
          path="purchased-works"
          element={
            <Suspense>
              <PurchasedWorkPage />
            </Suspense>
          }
        ></Route>
        <Route
          path="liked-works"
          element={
            <Suspense>
              <LikedWorksPage />
            </Suspense>
          }
        ></Route>
        <Route
          path="settings"
          element={
            <Suspense>
              <SettingsPage />
            </Suspense>
          }
        ></Route>
        <Route
          path="settings/:section"
          element={
            <Suspense>
              <SettingsPage />
            </Suspense>
          }
        ></Route>
        <Route
          path="galleries/:id"
          element={
            <Suspense>
              <GalleryPage />
            </Suspense>
          }
        ></Route>
        <Route
          path="videos/:id"
          element={
            <Suspense>
              <VideoPage />
            </Suspense>
          }
        ></Route>
        <Route
          path="deposit"
          element={
            <Suspense>
              <DepositIndexPage />
            </Suspense>
          }
        ></Route>
        <Route
          path="deposit/:token"
          element={
            <Suspense>
              <DepositPaymentPage />
            </Suspense>
          }
        ></Route>
        <Route
          path="inquiry"
          element={
            <Suspense>
              <InquiryPage />
            </Suspense>
          }
        ></Route>
        <Route
          path="personal"
          element={
            <Suspense>
              <PersonalPage />
            </Suspense>
          }
        ></Route>
        <Route
          path="*"
          element={
            <Suspense>
              <NotFoundPage />
            </Suspense>
          }
        ></Route>
      </Route>
    </Routes>
  );
}

import {
  Box,
  HStack,
  Text,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import { useMatch, useNavigate } from "react-router-dom";
import SidebarItem from "@components/sidebar/item";
import { useFirebase } from "@context/firebase.context";
import VStack from "@components/stack/vstack";
import { useTranslation } from "react-i18next";
import { Suspense, lazy } from "react";
import { signOut } from "firebase/auth";
import { useApp } from "context/app.context";
// import Donation from "./donation";
const ChangeLanguageItem = lazy(() =>
  import("@components/sidebar/change-language"),
);

export default function Sidebar({ onTouchStart, onTouchEnd }) {
  const { user, isAuthLoading, auth } = useFirebase();
  const { toastPosition } = useApp();
  const toast = useToast();
  const { t } = useTranslation();
  const isIndex = useMatch({ path: "/" });
  const isPersonal = useMatch({ path: "/personal" });
  const isAuthLogin = useMatch({ path: "/auth/login" });
  const isLikedWorks = useMatch({ path: "/liked-works" });
  const isPurchasedWorks = useMatch({ path: "/purchased-works" });
  const isDeposit = useMatch({ path: "/deposit" });
  const navigate = useNavigate("/");
  const inDrawer = useBreakpointValue({
    base: true,
    lg: false,
  });
  const logout = async () => {
    await signOut(auth);
    navigate("/");
  };

  return (
    <>
      <VStack width={"full"}>
        <VStack
          width={"full"}
          paddingX={4}
          paddingY={4}
          borderBottomWidth={"1px"}
          borderColor={"gray.700"}
        >
          <SidebarItem
            to="/"
            title={t("components.sidebar.home")}
            icon={<i className="fa-regular fa-house"></i>}
            isActive={isIndex}
          />
        </VStack>
        <VStack
          width={"full"}
          paddingX={4}
          paddingY={4}
          borderBottomWidth={"1px"}
          borderColor={"gray.700"}
        >
          <HStack
            width={"full"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Text paddingX={4} fontSize={"lg"} fontWeight={"bold"}>
              {t("components.sidebar.my_pages")}
            </Text>
          </HStack>
          <Box marginTop={4} width={"full"}>
            {user && inDrawer && (
              <SidebarItem
                to="/personal"
                title={t("components.sidebar.personal")}
                icon={<i className="fa-regular fa-user"></i>}
                isActive={isPersonal}
              />
            )}
            {process.env.REACT_APP_ENABLE_DEPOSIT === "true" && user && (
              <SidebarItem
                to="/deposit"
                title={t("components.sidebar.deposit")}
                icon={
                  <i className="fa-regular fa-money-simple-from-bracket"></i>
                }
                isActive={isDeposit}
              />
            )}
            {!isAuthLoading && !user && (
              <SidebarItem
                to="/auth/login"
                title={t("components.sidebar.login")}
                icon={<i className="fa-regular fa-right-to-bracket"></i>}
                isActive={isAuthLogin}
              />
            )}
            {!isAuthLoading && user && (
              <>
                <SidebarItem
                  to="/liked-works"
                  title={t("components.sidebar.liked_works")}
                  isActive={isLikedWorks}
                  icon={<i className="fa-regular fa-heart"></i>}
                />
                <SidebarItem
                  to="/purchased-works"
                  title={t("components.sidebar.purchased_works")}
                  isActive={isPurchasedWorks}
                  icon={<i className="fa-regular fa-bag-shopping"></i>}
                />
              </>
            )}
          </Box>
        </VStack>
        <VStack
          width={"full"}
          paddingX={4}
          paddingY={4}
          borderBottomWidth={"1px"}
          borderColor={"gray.700"}
        >
          <Suspense>
            <ChangeLanguageItem />
          </Suspense>
          <SidebarItem
            to="/faq"
            title={t("components.sidebar.faq")}
            isActive={useMatch({ path: "/faq" })}
            icon={<i className="fa-regular fa-circle-question"></i>}
          />
          <SidebarItem
            to="/privacy-policies"
            title={t("components.sidebar.privacy_policies")}
            isActive={useMatch({ path: "/privacy-policies" })}
            icon={<i className="fa-regular fa-blinds-open"></i>}
          />
          <SidebarItem
            to="/terms-of-use"
            title={t("components.sidebar.terms_of_use")}
            isActive={useMatch({ path: "/terms-of-use" })}
            icon={<i className="fa-regular fa-scale-balanced"></i>}
          />
          <SidebarItem
            to="/inquiry"
            title={t("components.sidebar.inquiry")}
            isActive={useMatch({ path: "/inquiry" })}
            icon={<i className="fa-regular fa-message-arrow-up-right"></i>}
          />
          {user && (
            <SidebarItem
              onClick={logout}
              title={t("components.sidebar.logout")}
              icon={<i className="fa-regular fa-arrow-right-from-bracket"></i>}
            />
          )}
        </VStack>
        <VStack
          width={"full"}
          paddingX={4}
          paddingY={4}
          borderBottomWidth={"1px"}
          borderColor={"gray.700"}
        >
          <SidebarItem
            onClick={() =>
              window.open("https://instagram.com/mizugi.space", "_blank")
            }
            title="Instagram"
            icon={<i className="fa-brands fa-instagram"></i>}
          />
          <SidebarItem
            onClick={() =>
              window.open("https://www.facebook.com/space.mizugi/", "_blank")
            }
            title="Facebook"
            icon={<i className="fa-brands fa-facebook"></i>}
          />
          <SidebarItem
            onClick={() => {
              toast({
                title: t("components.sidebar.coming_soon"),
                duration: 2000,
                status: "success",
                position: toastPosition,
              });
            }}
            title="Discord"
            icon={<i className="fa-brands fa-discord"></i>}
          />
          {/* <Donation /> */}
        </VStack>
      </VStack>
    </>
  );
}
